.Hero_section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("https://preview.colorlib.com/theme/coffee/img/header-bg.jpg.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
