.About_video {
    background:url("https://preview.colorlib.com/theme/coffee/img/video-bg.jpg.webp");
    background-size: cover;
    display: flex;
    height: 360px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}
.video-left {
    padding-left: 10%;
}
.About_content {
    padding-left: 20%;
}
.overlay_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    width: 100%;
    height: 100%;
}
.About_video_icon {
    font-size: 8rem;
    font-weight: 100;
    color: #F9CA02;
}