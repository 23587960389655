.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-fixed);
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.2);
}
.header .Cafe {
    color: #b68834;
}
.header_contact {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0.5rem 1rem;
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo {
    font-size: 1.8rem;
    color: white;
    font-weight: 800;
}

.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__link {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    color: white;
    font-weight: 400;
    text-transform: uppercase;
    transition: .3s;
    position: relative;
}
.nav__link::before {
    content:' ';
    position: absolute;
    bottom:0;
    width: 0;
    height: 2px;
    background-color: var(--brown);
    transition: width .3s;
}
.nav__link:hover::before{
    width: 100%;
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}


/* cative link*/



/*==========BREAK POINT ========*/
/* For medium devices */

@media screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        right: -100%;
        bottom: 0;
        width: 300px;
        background-color: rgba(0, 0, 0, 1);
        color: white;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 0 0 1.5rem;
        transition: .3s;
        z-index: var(--z-fixed);
    }
    .header_contact {
        display:none;
    }

    /* Show menu */

    .show-menu {
        right: 0%;
    }

    .nav__list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
    }


    .nav__icon {
        font-size: 2.2rem;
    }

    .nav_close {
        position: absolute;
        top: 1.5rem;
        right: 5%;
        font-size: 2.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover {
        color: var(--title-color-dark);
    }


    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
    }

    .nav__link {
        color: white;
    }


    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }

}

/* For small devices */
@media screen and (max-width: 350px){
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }
    .nav__list {
        column-gap: 0;

    }
    .header_contact {
        display:none;
    }
}