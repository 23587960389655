.Coffee_section {
    background: url("https://preview.colorlib.com/theme/coffee/img/menu-bg.jpg") center;
    background-size: cover;
}
.menu_card {
    box-shadow: 0px 10px 30px 0px rgba(182, 136, 52, 0.2);
    border-radius: 10px;
    padding: 30px 30px;
    margin: 15px 5px;
    background-color: white;
}
.menu_card p {
    font-size: 18px;
    color: #777777;
    font-weight: 300;
}
.menu_card h4 {
    color: #222222;
    line-height: 1.2em;
    font-weight: 600;
    font-size: 20px;
}
.menu_card .price {
    font-size: 20px;
    font-weight: 600;
    color: #b68834;
}