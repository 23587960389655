.review_section {
    background: var(--brown);
}
.stars .star_icon {
    color: #ffffff;
    font-size: 16px;
}
.stars .active {
    color: #ffa500;
}
.count_numbers {
    font-size: 60px;
    font-weight: 600;
    color: #b68834;
}